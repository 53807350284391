import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http:HttpClient) { }

  public getCostingHeadsByVendor() {
    return this.http.get<any>(`/api/transactions`);
  }

  public getCostHeadsWithoutFreight(vendorId) {
    return this.http.get<any>(`/api/transactions/cost-heads/no-freight/${vendorId}`);
  }

  public getCostHeadsWithFreight(vendorId) {
    return this.http.get<any>(`/api/transactions/cost-heads/with-freight/${vendorId}`);
  }

  public getOthersCostHeads(vendorId) {
    return this.http.get<any>(`/api/transactions/cost-heads/others/${vendorId}`);
  }

  public getApproveRequests(vendorId) {
    return this.http.get<any>(`/api/transactions/price-request?vendorId=${vendorId}`);
  }

  //Submit Forms

  public submitPriceMaintenance(data) {
    return this.http.post<any>(`/api/transactions/cost-heads/add-no-freight`, data);
  }

  public submitFreightMaintenance(data) {
    return this.http.post<any>(`/api/transactions/cost-heads/add-with-freight`, data);
  }

  public submitOtherMaintenance(data) {
    return this.http.post<any>(`/api/transactions/cost-heads/add-others`, data);
  }

  public submitPriceRequest(data) {
    return this.http.post<any>(`/api/transactions/price-request`, data);
  }

  public approvePriceRequest(data) {
    return this.http.post<any>(`/api/transactions/approve-request`, data);
  }
  
  // History

  public getCostingSheetsByVendorId(vendorId) {
    return this.http.get<any>(`/api/costing-sheets/by-vendor/${vendorId}`);
  }

  public getPlantsByVendorId(vendorId) {
    return this.http.get<any>(`/api/plant-master/by-vendor/${vendorId}`);
  }

  public getCostingHeadByCostSheetId(vendorId) {
    return this.http.get<any>(`/api/costing-heads/by-cost-sheet/${vendorId}`);
  }

  public getSingleCostingHead(id) {
    return this.http.get<any>(`/api/costing-heads/${id}`);
  }

  public getSingleVendor(id) {
    return this.http.get<any>(`/api/vendor/${id}`);
  }

  public getSinglePlant(id) {
    return this.http.get<any>(`/api/plant-master/${id}`);
  }
  
  public getCostHeadsHistoryWithoutFreight(vendorId, active) {
    return this.http.get<any>(`/api/transactions/history/no-freight/${vendorId}?active=${active}`);
  }

  public getCostHeadsHistoryWithFreight(vendorId, plantId, month) {
    return this.http.get<any>(`/api/transactions/history/with-freight/${vendorId}/${plantId}?month=${month}`);
  }

  public getLandedPriceHistory(vendorId, costSheetId, plantId) {
    return this.http.get<any>(`/api/transactions/history/landed-price-history/${vendorId}?costSheetId=${costSheetId}&plantId=${plantId}`);
  }

  // Read Vendors

  public getOnlyVendors() {
    return this.http.get<any>(`/api/user/all-vendors`);
  }

  public getOnlyVendorsRaisedRequests() {
    return this.http.get<any>(`/api/user/all-vendors/raised-requests`);
  }

  //Read Plants

  public getAllPlants() {
    return this.http.get<any>(`/api/assign-plant/all-plants`);
  }

  // Single Costing Sheet
  
  public getSingleCostingSheet(id) {
    return this.http.get<any>(`/api/costing-sheets/${id}`);
  }
}
