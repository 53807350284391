import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { InnerNavbarComponent } from './inner-navbar/inner-navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SendMailModalComponent } from './send-mail-modal/send-mail-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SharedComponent, NavbarComponent, DeleteModalComponent, InnerNavbarComponent, FooterComponent, SendMailModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports:[
    NavbarComponent,
    DeleteModalComponent,
    SendMailModalComponent,
    InnerNavbarComponent,
    FooterComponent,
  ]
})
export class SharedModule { }
