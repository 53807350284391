// Angular
import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-send-mail-modal',
  templateUrl: './send-mail-modal.component.html',
  styleUrls: ['./send-mail-modal.component.scss']
})
export class SendMailModalComponent implements OnInit {

  emailsArr = new FormControl("", [Validators.required, Validators.pattern('^[\\W]*([\\w+\\-.%]+@[\\w\\-.]+\\.[A-Za-z]{2,4}[\\W]*,{1}[\\W]*)*([\\w+\\-.%]+@[\\w\\-.]+\\.[A-Za-z]{2,4})[\\W]*$')]);
// Public properties
viewLoading = false;

/**
 * Component constructor
 *
 * @param dialogRef: MatDialogRef<SendMailModalComponent>
 * @param data: any
 */
@Input() isDisabled: boolean;
@Input() value
@Output() action = new EventEmitter();

constructor(
  public dialogRef: MatDialogRef<SendMailModalComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) { }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
  this.emailsArr.patchValue(this.data.email + ',')
}

/**
 * Close dialog with false result
 */
onNoClick(): void {
  this.dialogRef.close();
}

/**
 * Close dialog with true result
 */
onYesClick(): void {
  /* Server loading imitation. Remove this */
  this.viewLoading = true;
  // setTimeout(() => {
  this.dialogRef.close(this.emailsArr.value); // Keep only this row
  // }, 2500);
}

}
