import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlantMasterService {

  constructor(private http:HttpClient) { }

  public getPlants(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/plant-master?from=${from}&to=${to}&search=${search}`);
  }

  public addPlant(formData){
    return this.http.post('/api/plant-master/', formData) ; 
  }

  public getSinglePlant(id) {
    return this.http.get<any>(`/api/plant-master/${id}`);
  }

  public updatePlant(id, formData) {
    return this.http.put<any>(`/api/plant-master/${id}`, formData);
  }

  public deletePlant(id) {
    return this.http.delete<any>(`/api/plant-master/${id}`);
  }
}
