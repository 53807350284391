// Angular
import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

// Public properties
viewLoading = false;

/**
 * Component constructor
 *
 * @param dialogRef: MatDialogRef<DeleteModalComponent>
 * @param data: any
 */

@Input() isDisabled: boolean;
@Input() value
@Output() action = new EventEmitter();

constructor(
  public dialogRef: MatDialogRef<DeleteModalComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) { }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
}

/**
 * Close dialog with false result
 */
onNoClick(): void {
  this.dialogRef.close();
}

/**
 * Close dialog with true result
 */
onYesClick(): void {
  /* Server loading imitation. Remove this */
  this.viewLoading = true;
  // setTimeout(() => {
  this.dialogRef.close(true); // Keep only this row
  // }, 2500);
}

}
