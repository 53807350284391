import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssignCostSheetService {

  constructor(private http:HttpClient) { }

  public getAllVendors() {
    return this.http.get<any>(`/api/assign-cost-sheet/all-vendors`);
  }

  public getAllCostingSheets() {
    return this.http.get<any>(`/api/costing-sheet-structures/costing-sheets`);
  }
  
  public getAssignCostSheet(id, from: number, to: number, search: string) {
    return this.http.get<any>(`/api/assign-cost-sheet/by-vendor/${id}?from=${from}&to=${to}&search=${search}`);
  }

  public addAssignCostSheet(formData){
    return this.http.post('/api/assign-cost-sheet/', formData) ; 
  }

  public getSingleAssignCostSheet(id) {
    return this.http.get<any>(`/api/assign-cost-sheet/${id}`);
  }

  public updateAssignCostSheet(id, formData) {
    return this.http.put<any>(`/api/assign-cost-sheet/${id}`, formData);
  }

  public deleteAssignCostSheet(id) {
    return this.http.delete<any>(`/api/assign-cost-sheet/${id}`);
  }

  public getSingleVendor(id) {
    return this.http.get<any>(`/api/vendor/${id}`);
  }
}
