import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RoleMasterService {

  constructor(private http:HttpClient) { }

  public getRoles(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/role?from=${from}&to=${to}&search=${search}`);
  }

  public addRole(formData){
    return this.http.post('/api/role/', formData) ; 
  }

  public getSingleRole(id) {
    return this.http.get<any>(`/api/role/${id}`);
  }

  public updateRole(id, formData) {
    return this.http.put<any>(`/api/role/${id}`, formData);
  }

  public deleteRole(id) {
    return this.http.delete<any>(`/api/role/${id}`);
  }


  public getUsers() {
    return this.http.get<any>(`/api/user/`);
  }

  public getSingleUser(id) {
    return this.http.get<any>(`/api/user/${id}`);
  }

  public assignRole(id, formData){
    return this.http.put(`/api/role/assign-role/${id}`, formData) ; 
  }

  getDashModulePermission(id): Observable<any> {
    return this.http.get('/api/access/' + id).pipe(
      map(res => res)
    );
  }

  getMobileModulePermission(id){
	  return this.http.get('/api/access/mobile/' + id);
  }

  getMobileComponentPermission(moduleName , id){
    return this.http.post('/api/access/get-sub-modules/', {moduleName , id} );
  }

  getRolesAndPermission(id){
    return this.http.get('/api/role/'+ id);  
  }

  // updateModulePermission(roleId,moduleName,value){
  //   return this.http.post<any>('/api/access/add-by-module/',{roleId,moduleName,value});
  // }

  updateModulePermission(roleId,subModuleName,value){
    return this.http.post<any>('/api/access/add-by-sub-module/' , {roleId,subModuleName,value});
  }

  getComponentPermission(roleId,subModuleName,value){
    return this.http.post<any>('/api/access/add-by-sub-module/' , {roleId,subModuleName,value});
  }

  getActionPermission(roleId,actionId,value){
    return this.http.post<any>('/api/access/add-by-action/' , {roleId,actionId,value});
  }
}
