import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CostingSheetsService {

  constructor(private http:HttpClient) { }

  public getCostingSheets(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/costing-sheets?from=${from}&to=${to}&search=${search}`);
  }

  public addCostingSheet(formData){
    return this.http.post('/api/costing-sheets/', formData) ; 
  }

  public getSingleCostingSheet(id) {
    return this.http.get<any>(`/api/costing-sheets/${id}`);
  }

  public updateCostingSheet(id, formData) {
    return this.http.put<any>(`/api/costing-sheets/${id}`, formData);
  }

  public deleteCostingSheet(id) {
    return this.http.delete<any>(`/api/costing-sheets/${id}`);
  }
}
