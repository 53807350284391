import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http:HttpClient) { }

  //USERS

  public getUsers(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/user?from=${from}&to=${to}&search=${search}`);
  }

  public getRoles() {
    return this.http.get<any>(`/api/role/no-vendor`);
  }

  public addUser(formData){
    return this.http.post('/api/user/', formData) ; 
  }

  public getSingleUser(id) {
    return this.http.get<any>(`/api/user/${id}`);
  }

  public updateUser(id, formData) {
    return this.http.put<any>(`/api/user/${id}`, formData);
  }

  public deleteUser(id) {
    return this.http.delete<any>(`/api/user/${id}`);
  }

}

