import { UserLogin } from './userLogin';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthService {
  userId: any;
  userName: any;
  userNumber: any;
  lastSeen: any;
  userData: Array<any> = [];
  constructor(private http: HttpClient) { }

  getRoleName(id) {
    return this.http.get<any>(`/api/role/${id}`)
  }

  userLogin(loginUser: UserLogin): Observable<any> {
    return this.http.post<any>(`/api/auth/login`, loginUser)
  }

  logoutUser() {
    return this.http.post('/api/auth/logout', '')
  }

  isLoggedIn() {
    return !!localStorage.getItem('userData');
  }

  saveUserData(data) {
    this.userId = data['0']['id'];
    this.userName = data['0']['name'];
    this.userNumber = data['0']['userNumber'];
    this.lastSeen = data['0']['lastSeen'];
  }

  microsoft() {
    return this.http.get('api/auth/microsoft');
  }

  singleSignOn(code) {
    return this.http.post(`/api/auth/sso`, { code: code });
  }
  // getModuleList(){
  //     let moduleData =  JSON.parse(localStorage.getItem("moduleList"));
  //     // this.moduleList = moduleData.map(data => data.module_name);
  //     // return this.moduleList;
  //     // return "ABC";
  //   }

  returnUserData() {
    return this.userData = [{
      "id": this.userId,
      "name": this.userName,
      "number": this.userNumber,
      "lastSeen": this.lastSeen
    }]
  }

  returnLocalStorageData(): Observable<any> {
    let local = JSON.parse(localStorage.getItem('userData'));
    return of(local);
  }

  generateOtp(email: any) {
    return this.http.post<any>(`/api/auth/send-otp`, { email })
  }

  verifyOtp(userId, otp: number) {

    return this.http.post<any>(`/api/auth/verify-otp`, { userId, otp })
  }

  resendOtp(userId) {
    return this.http.post<any>(`/api/auth/resend-otp`, { userId })
  }

  changeForgotPassword(userData) {
    return this.http.post<any>(`/api/auth/update-password`, userData);
  }

}
