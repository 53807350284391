import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashPermissionsService {

  constructor() { }

  getModulePermissions(){
    let data = JSON.parse(localStorage.getItem('userData'));
    return data.permissions;
  }

}
