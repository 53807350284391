import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CostingHeadsService {

  constructor(private http:HttpClient) { }

  public getCostingHeads(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/costing-heads?from=${from}&to=${to}&search=${search}`);
  }

  public addCostingHead(formData){
    return this.http.post('/api/costing-heads/', formData) ; 
  }

  public getSingleCostingHead(id) {
    return this.http.get<any>(`/api/costing-heads/${id}`);
  }

  public updateCostingHead(id, formData) {
    return this.http.put<any>(`/api/costing-heads/${id}`, formData);
  }

  public deleteCostingHead(id) {
    return this.http.delete<any>(`/api/costing-heads/${id}`);
  }
}
