import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http:HttpClient) { }

  public getAllCostingSheets() {
    return this.http.get<any>(`/api/costing-sheet-structures/costing-sheets`);
  }
  
  public getTheReport(costSheetId, month) {
    return this.http.get<any>(`/api/transactions/report/${costSheetId}/${month}`);
  }

  public getTheReportByVendor(costSheetId, vendorId, month) {
    return this.http.get<any>(`/api/transactions/report-by-vendor/${costSheetId}/${vendorId}/${month}`);
  }

  public mailPDF(costSheetId, vendorId, month, recipients) {
    return this.http.get<any>(`/api/transactions/report-by-vendor/pdf-mail/${costSheetId}/${vendorId}/${month}?recipients=${recipients}`);
  }

  public getSingleVendor(id) {
    return this.http.get<any>(`/api/vendor/${id}`);
  }

  public getSingleCostingSheet(id) {
    return this.http.get<any>(`/api/costing-sheets/${id}`);
  }

  public getLatestMonth(costSheetId) {
    return this.http.get<any>(`/api/transactions/latest-month-of-cost-sheet/${costSheetId}`);
  }
}
