import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VendorListService {

  constructor(private http:HttpClient) { }

  public getAllVendorList(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/assign-cost-sheet/list?from=${from}&to=${to}&search=${search}`);
  }

  public getVendorList(from: number, to: number, search: string) {
    return this.http.get<any>(`/api/vendor?from=${from}&to=${to}&search=${search}`);
  }

  public addVendor(formData){
    return this.http.post('/api/vendor', formData) ; 
  }

  public getSingleVendor(id) {
    return this.http.get<any>(`/api/vendor/${id}`);
  }

  public updateVendor(id, formData) {
    return this.http.put<any>(`/api/vendor/${id}`, formData);
  }

  public deleteVendor(id) {
    return this.http.delete<any>(`/api/vendor/${id}`);
  }

  public getRoles() {
    return this.http.get<any>(`/api/role`);
  }

  public mailVendor(id) {
    return this.http.get<any>(`/api/transactions/mail-reminder/${id}`);
  }

 }

