import { DashPermissionsService } from './../../../core/services/dashboard/dash-permissions.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../../../core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-navbar',
  templateUrl: './inner-navbar.component.html',
  styleUrls: ['./inner-navbar.component.scss']
})
export class InnerNavbarComponent implements OnInit {
  fullName: string;
  public logout: boolean = false;
  modulePermissions: any;
  subModulePermissions: any;
  roleId: any;
  roleName: any;

  constructor(private auth:AuthService,
              private toastr:ToastrService,
              private router:Router,
              private dashService:DashPermissionsService,
                  
    ) { }

  ngOnInit() {

    let tempDash = this.dashService.getModulePermissions();
    this.modulePermissions = tempDash.map(ele=>ele.module_name);
    this.subModulePermissions = tempDash.map(ele=>ele.sub_module_name);
    
    this.auth.returnLocalStorageData().subscribe(res => {
      console.log(res);
      this.fullName = res.userData.firstName + ' ' + res.userData.lastName
      this.roleId = res.userData.roleId;
      this.auth.getRoleName(this.roleId).subscribe(res => {
        this.roleName = res.roleName;
    });
  });
}

  logoutUser() {
    this.logout = true;
    this.auth.logoutUser().subscribe(
      succ => {
        this.toastr.success('Success', 'Logout Successfully', {
          timeOut: 3000
        });
        this.logout = false;
        localStorage.clear();
        this.router.navigate(['/login/log-out']);
      },
      error => {
        this.logout = false;

      }
    );
  }

  navigateCostingHeads(){
    this.router.navigate(['/master/costing-heads']);
  }

  navigateCostingSheets(){
    this.router.navigate(['/master/costing-sheets']);
  }

  navigateHome(){
    this.router.navigate(['/dashboard']);
  }

  navigatePlantMaster(){
    this.router.navigate(['/master/plant-master']);
  }

  navigateRole(){
    this.router.navigate(['/master/role-master']);
  }

  navigateVendorList(){
    this.router.navigate(['/vendor/vendor-list']);
  }

  navigateChangePassword(){
    this.router.navigate(['/vendor/change-password']);
  }

  navigateUsers(){
    this.router.navigate(['/master/users']);
  }

  navigateMyCostHeads(){
    this.router.navigate(['/transactions/my-cost-heads']);
  }

  navigatePriceMaintenance(){
    this.router.navigate(['/transactions/price-maintenance']);
  }

  navigateApproveRequests(){
    this.router.navigate(['/transactions/approve-requests']);
  }

  navigateReports(){
    this.router.navigate(['/transactions/reports']);
  }

  navigatePriceHistory(){
    this.router.navigate(['/transactions/history']);
  }

  navigateLandedPriceHistory(){
    this.router.navigate(['/transactions/landed-price-history']);
  }

}
