import { DeleteModalComponent } from './../../../views/shared/delete-modal/delete-modal.component';
// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';

@Injectable()
export class LayoutUtilsService {
	/**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
	constructor(private dialog: MatDialog) { }


	deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
		return this.dialog.open(DeleteModalComponent, {
			data: { title, description, waitDesciption },
			width: '440px'
		});
	}

}
