import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'login', loadChildren: () => import('../app/views/pages/login/login.module').then(m => m.LoginModule) },

  {
    path: '', loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'master', loadChildren: () => import('../app/views/pages/masters/masters.module').then(m => m.MastersModule),
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'vendor', loadChildren: () => import('../app/views/pages/vendors/vendors.module').then(m => m.VendorsModule),
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'transactions', loadChildren: () => import('../app/views/pages/transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [
      AuthGuard
    ]
  }



];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled', // Add options right here
			relativeLinkResolution: 'legacy',
			useHash: true 
		  }),
	],
  exports: [RouterModule]
})
export class AppRoutingModule { }
