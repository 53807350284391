import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssignPlantService {

  constructor(private http:HttpClient) { }

  public getAllVendors() {
    return this.http.get<any>(`/api/assign-cost-sheet/all-vendors`);
  }

  public getAllPlants() {
    return this.http.get<any>(`/api/assign-plant/all-plants`);
  }
  
  public getAssignPlant(id,from: number, to: number, search: string) {
    return this.http.get<any>(`/api/assign-plant/by-vendor/${id}?from=${from}&to=${to}&search=${search}`);
  }

  public addAssignPlant(formData){
    return this.http.post('/api/assign-plant/', formData) ; 
  }

  public getSingleAssignPlant(id) {
    return this.http.get<any>(`/api/assign-plant/${id}`);
  }

  public updateAssignPlant(id, formData) {
    return this.http.put<any>(`/api/assign-plant/${id}`, formData);
  }

  public deleteAssignPlant(id) {
    return this.http.delete<any>(`/api/assign-plant/${id}`);
  }

  public getSingleVendor(id) {
    return this.http.get<any>(`/api/vendor/${id}`);
  }

  public getAllFreights() {
    return this.http.get<any>(`/api/assign-plant/cost-heads/is-freight`);
  }
}
