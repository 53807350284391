import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  fullName: string;
  time = new Date();
  timer;
  title: string = "";
  constructor(
    private auth: AuthService,
    private changeRef: ChangeDetectorRef,
    public aRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {

    let newUrl = this.router.url.split('/');
    let path = newUrl[2];

    switch (path) {
      case 'katha-soap-line-2':
        this.title = 'Katha Soap Line -2';
        break;
      case 'thana-soap-line':
        this.title = 'Thana Soap Line';
        break;
      case 'guwhati-multitrack-1':
        this.title = 'Guwhati Multitrack 1';
        break;
      case 'guwhati-multitrack-2':
        this.title = 'Guwhati Multitrack 2';
        break;
      case 'guwhati-autopack-1':
        this.title = 'Guwhati Autopack 1';
        break;
      case 'guwhati-autopack-2':
        this.title = 'Guwhati Autopack 2';
        break;
      case 'guwhati-autopack-3':
        this.title = 'Guwhati Autopack 3';
        break;
      case 'iot-refill-1':
        this.title = 'IOT Refill 1';
        break;
      case 'iot-refill-2':
        this.title = 'IOT Refill 2';
        break;
      default:
        this.title = '';

    }


    this.timer = setInterval(() => {
      this.time = new Date();
      this.time.setMinutes( this.time.getMinutes() - 20 );
      this.changeRef.detectChanges();
    }, 1000);

    this.auth.returnLocalStorageData().subscribe(res => {
      console.log(res)
      this.fullName = res.userData.firstName + ' ' + res.userData.lastName
    })
  }

  ngOnDestroy() {
    clearInterval(this.timer)
  }

  back() {
    window.history.back()
  }
}
