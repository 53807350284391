import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//Rxjs
import { Observable } from 'rxjs';

import { map, tap, catchError, share, timeout } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MembersService {


	constructor(
		private _http: HttpClient,
	) { }



	changePassword(data: any): Observable<any> {
		let passwordData = {
			currentPass: data.currentPass.trim(),
			newPass: data.newPass.trim(),
			confirmPass : data.confirmPass.trim()
		}
		return this._http.post<any>(`/api/auth/change-password`, passwordData).pipe(
			(tap(done => {
				return done
			})),
			(catchError(error => {
				throw error;
			})),
			share()
		)
	}


}
