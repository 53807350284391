import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CostingSheetStructureService {

  constructor(private http:HttpClient) { }

  public getAllCostingSheets() {
    return this.http.get<any>(`/api/costing-sheet-structures/costing-sheets`);
  }

  public getAllCostingHeads() {
    return this.http.get<any>(`/api/costing-sheet-structures/costing-heads`);
  }
  
  public getCostingSheetStructures(id, from: number, to: number, search: string) {
    return this.http.get<any>(`/api/costing-sheet-structures/by-costing-sheet/${id}?from=${from}&to=${to}&search=${search}`);
  }

  public addCostingSheetStructure(formData){
    return this.http.post('/api/costing-sheet-structures/', formData) ; 
  }

  public getSingleCostingSheetStructure(id) {
    return this.http.get<any>(`/api/costing-sheet-structures/${id}`);
  }

  public updateCostingSheetStructure(id, formData) {
    return this.http.put<any>(`/api/costing-sheet-structures/${id}`, formData);
  }

  public deleteCostingSheetStructure(id) {
    return this.http.delete<any>(`/api/costing-sheet-structures/${id}`);
  }

  public getSingleCostingSheet(id) {
    return this.http.get<any>(`/api/costing-sheets/${id}`);
  }
}
