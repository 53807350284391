import { ReportsService } from './services/transactions/reports.service';
import { TransactionsService } from './services/transactions/transactions.service';
import { MembersService } from './services/vendors/members.service';
import { UsersService } from './services/masters/users';
import { AssignPlantService } from './services/vendors/assign-plant';
import { AssignCostSheetService } from 'src/app/core/services/vendors/assign-cost-sheet';
import { VendorListService } from './services/vendors/vendor-list';
import { CostingSheetStructureService } from 'src/app/core/services/masters/costing-sheet-structure.service';
import { PlantMasterService } from './services/masters/plant-service';
import { CostingSheetsService } from './services/masters/costing-sheets.service';
import { CostingHeadsService } from 'src/app/core/services/masters/costing-heads.service';
import { RoleMasterService } from './services/masters/role-master.service';
import { NumberDirective } from './directives/number-only.directive';
import { SharedModule } from './../views/shared/shared.module';
import { DeleteModalComponent } from './../views/shared/delete-modal/delete-modal.component';
import { LayoutUtilsService } from './services/crud/layout-utils.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { MatDialogRef, MatDialogModule } from '@angular/material';
import { EnviornmentService } from './services/auth/enviornment.service';


@NgModule({
  declarations: [
    NumberDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    SharedModule,
  ],
  providers:[
    AuthService,
    AuthGuard,
    LayoutUtilsService,
    RoleMasterService,
    CostingHeadsService,
    CostingSheetsService,
    PlantMasterService,
    CostingSheetStructureService,
    VendorListService,
    AssignCostSheetService,
    AssignPlantService,
    UsersService,
    MembersService,
    TransactionsService,
    ReportsService,
    EnviornmentService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
    entryComponents:[DeleteModalComponent]
  
})  
export class CoreModule { }
