import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnviornmentService {
  public domain: string;

  constructor() {
    this.domain = window.location.hostname;
  }

  returnDomain() {

    if (this.domain == 'localhost') {
      console.log('Working at localhost !');
      return "http://127.0.0.1:3003"
    } else if (this.domain == 'gcplapp.thewebdepot.cloud') {
      console.log('Working at UAT.');
      return `https://${this.domain}`
    } else {
      console.log(`Working at AWS`);
      return `https://${this.domain}`
    }
  }



}
